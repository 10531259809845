<template>
  <div class="mt-10 px-4">
    <!-- background -->
    <div class="background-ball-big"></div>
    <div class="background-ball-small"></div>

    <div class="pt-4 title">
      <h3>Sign In</h3>
    </div>

    <wap-text-field
      label="Enter Email"
      v-model="dataSources.current.email"
      @change="layouts.actives.alert = false"
    >
    </wap-text-field>
    <wap-text-field
      label="Enter Password"
      :append-icon="layouts.actives.showPwd ? 'mdi-eye' : 'mdi-eye-off'"
      :type="layouts.actives.showPwd ? 'text' : 'password'"
      v-model="dataSources.current.password"
      @click:append="layouts.actives.showPwd = !layouts.actives.showPwd"
      @change="layouts.actives.alert = false"
    >
    </wap-text-field>

    <a class="pull-right f-s-13" @click="$router.push('/send-reset-link')">Forgot Password?</a>

    <wap-btn class="mt-4 width-full" :disabledControl="layouts.actives.alert" @click="clickSignIn"> Sign In </wap-btn>

    <div class="d-flex align-center my-8">
      <v-divider></v-divider>
      <span class="px-4">Or</span>
      <v-divider></v-divider>
    </div>

    <div class="text-center">
      Not registered yet? <a class="f-w-600" @click="$router.push('/send-set-link')">Create Account</a>
    </div>
  </div>
</template>

<script>
import WapTextField from '@/components/base/WapTextField'
import WapBtn from '../../components/base/WapBtn.vue'
import { AccountApi } from '@/api'
import { Cache, DataType, Tool, Crypto } from '@/assets/js/util'

export default {
  components: { WapTextField, WapBtn },
  data () {
    return {
      assists: {
        tool: new Tool(),
        cache: new Cache()
      },
      dataSources: {
        current: {
          email: '',
          password: ''
        }
      },
      layouts: {
        actives: {
          showPwd: false,
          alert: false
        }
      }
    }
  },
  methods: {
    clickSignIn () {
      const { email, password } = this.dataSources.current

      if (
        !DataType.ValidationRules.EMAIL.test(email) ||
        this.assists.tool.isEmpty(email) ||
        this.assists.tool.isEmpty(password)
      ) {
        this.$store.commit('setPopupInformation', {
          message: 'Incorrect email or password.'
        })
        this.layouts.actives.alert = true
        return
      }

      AccountApi.signIn(
        {
          portfolioId: this.$store.getters.getPortfolioId,
          email,
          password: Crypto.md5(password)
        },
        (res) => {
          this.assists.cache.setAccessToken(res)
          this.$router.push('/home')
        },
        (err) => {
          this.layouts.actives.alert = true
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    }
  }
}
</script>

<style lang='sass' scoped>
.background-ball-big
  position: absolute
  top: -60vw
  left: -5vw
  width: 110vw
  height: 110vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.background-ball-small
  position: absolute
  top: -55vw
  right: -58vw
  width: 100vw
  height: 100vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.title
  height: 23vh
</style>
