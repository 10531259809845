<template>
  <div class="my-3">
    <div :style="'width:' + width">
      <v-text-field
        v-if="assist.tool.isEmpty(finalMask)"
        v-model="currentValue"
        :class="{ 'f-w-300': true }"
        hide-details="auto"
        v-bind="$attrs"
        v-on="$listeners"
        :autofocus="autofocus"
      >
        <template slot="label">
          {{ label }}<span v-if="necessarily" class="text-danger">*</span>
        </template>
        <template slot="append">
          <slot name="right">
            <v-icon v-if="assist.tool.isNotEmpty(icon)" class="pull-right mr-2" @click="onClickIcon">
              {{ icon }}
            </v-icon>
          </slot>
        </template>
        <template slot="append-outer">
          <slot name="append-outer">
          </slot>
        </template>
        <template slot="prepend-inner">
          <slot name="prepend-inner">
            <template v-if="format === 'currency'">$</template>
          </slot>
        </template>
      </v-text-field>
      <v-text-field
        v-else-if="finalMask"
        v-model="currentValue"
        v-mask="finalMask"
        :class="{ 'f-w-300': true }"
        hide-details="auto"
        v-bind="$attrs"
        v-on="$listeners"
        :autofocus="autofocus"
      >
        <template slot="label">
          {{ label }}<span v-if="necessarily" class="text-danger">*</span>
        </template>
        <template slot="append">
          <slot name="right">
            <v-icon v-if="assist.tool.isNotEmpty(icon)" class="pull-right mr-2" @click="onClickIcon">{{ icon }}
            </v-icon>
          </slot>
        </template>
        <template slot="append-outer">
          <slot name="append-outer">
          </slot>
        </template>
        <template slot="prepend-inner">
          <slot name="prepend-inner">
            <template v-if="format === 'currency'">$</template>
          </slot>
        </template>
      </v-text-field>
    </div>
    <div class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'wap-text-field',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: null,
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    necessarily: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (val) {
      this.currentValue = val
      this.$nextTick(() => {
        this.finalMask = this.mask
        this.$forceUpdate()
      })
    },
    currentValue () {
      if (this.currentValue !== this.value) {
        this.$emit('change', this.currentValue)
      }
    }
  },
  data () {
    return {
      assist: {
        tool: tool
      },
      currentValue: this.value,
      finalMask: ''
    }
  },
  mounted () {
    if (tool.isNotEmpty(this.value)) {
      this.finalMask = this.mask
    }
  },
  methods: {
    onClickIcon () {
      this.$emit('click-icon')
    }
  }
}
</script>

<style lang="sass" scoped>
  .error-message
    margin-top: 5px
    display: flex
    color: red
    font-size: 12px
    width: 100%
    line-height: 12px
    word-break: break-word
    word-wrap: break-word
</style>
